<template>
    <nav class="bg-dark container-fluid border-top pt-5 pb-3 text-xs text-center" id="footer">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <p class="text-secondary">
                        <a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/">
                            <img class="mb-2" alt="Creative Commons License" src="../assets/footer/cc-by-sa.png" height="20" width="auto">
                        </a>
                        <br>This work is licensed under a
                        <a class="link-secondary" rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/" target="_blank">Creative Commons Attribution-ShareAlike 4.0 International License
                        </a>.
                    </p>
                </div>

                <div class="col-lg-4 col-md-4 my-4" id="nav-ul">
                    <ul class="footer-links">
                        <li><router-link  class="text-white" :to="{ name:'Impressum' }">Impressum</router-link></li>
                        <li><a  class="text-white" href="https://www.lmu.de/de/footer/datenschutz/index.html" target="_blank">Datenschutz</a></li>
                        <li><router-link class="text-white" :to="{ name:'Nutzungsbedingungen' }">Nutzungsbedingungen</router-link></li>
                        <li><router-link class="text-white" :to="{ name:'Hilfe' }">Hilfe</router-link></li>
                    </ul>
                </div>

                <div class="col-lg-4 col-md-4">
                    <a class="navbar-brand" rel="license" href="https://www.lmu.de/de/index.html" target="_blank">
                        <img  :src="require('@/assets/footer/LMU_Logo.png')" width="150" class="d-inline-block align-right"/>
                    </a>
                </div>
            </div>
    </nav>

</template>

<script>
export default {
  name: 'NavFooter'
}
</script>

<style scoped>

.footer-links >li >a{
  text-decoration: none;
}
#nav-ul ul{ /*Footer-Start*/
      margin: 0;
      padding: 0;
  }
#nav-ul  ul li{
    list-style-type: none;
    display: inline;
}
#nav-ul li:not(:first-child):before {
    content: " | ";
}

a:hover{
    opacity: 0.5;
}

</style>