<template>
    <notifications
      group="error"
      :duration="5000"
      animation-type="velocity"
      :animation="animation"
      :pauseOnHover="true"
      position="top right">
      <template #body="{ item }">
        <div :class="item.type" class="msg-template py-2 d-flex align-items-center text-xs">
          <div :class="'msgIcon_'+item.type" class="msg_icon ps-3">
            <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
          </div>
          <div class="notification-content">
            <div class="msg-title fw-semibold">
              {{ item.title }}
            </div>
            
            <div class="notification-text" v-html="item.text"/>
          </div>
        </div>
      </template>
    </notifications>
    
</template>

<script>
export default {
  name: 'NotifiCations',
  methods:{
    close(){
        console.log("close");
        this.$notify.close;
    }
  },
  computed: {
  animation () {
    return {
      /**
       * Runs before animating, so you can take the initial height, width, color, etc
       * @param  {HTMLElement}  element  The notification element
       */
      enter (element) {
        let height = element.clientHeight
        return {
          // animates from 0px to "height"
          height: [height, 0],
        }
      },
      leave: {
        height: 0,
        opacity: 0
      }
    }
  }
}
}
</script>

<style lang="scss">
.msg-template {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  margin: 5px;
  margin-bottom: 0;
  &, & > div {
    box-sizing: border-box;
  }

  // types (green, amber, red)
  &.warn {
    background: #ffd66c;
    border-left: 5px solid #f48a06;
    color: #664d03;
  }
  &.error {
    background: #cce5ff;
    border-left: 5px solid #71b3fb;
    color: #025ab7;
  }
  .msg_icon{
    font-size: 22px;
    padding: 0 10px;
  }
  .msgIcon_error {
    flex: 0 1 auto;
    color: #025ab7;
  }
  .msgIcon_warn {
    flex: 0 1 auto;
    color: #664d03;
  }

  .msg-content {
    padding: 10px;
    flex: 1 0 auto;
    .msg-title {
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 10px;
    }
  }
}
</style>