<template>
    <nav class="navbar navbar-expand-lg pb-0 navbar-dark bg-dark header shadow-sm">
    <div class="row container-fluid d-flex justify-content-start">
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 navbar-brand logo">

          <div id="btn_newscrape_nav">
            <p class="text-sm">NewScrape</p>
          </div>

      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavHeader'
}
</script>


<style scoped>

/*Newscrapebutton Navbar*/
#btn_newscrape_nav >p{
  position: relative;
  z-index:5 !important;
  text-decoration: none;
  font-weight: 700 !important;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: black;
}

#btn_newscrape_nav {
  position: relative;
  letter-spacing: 0.15em;
  margin: 0 auto;
  padding: .9rem 2rem;
  background: transparent;
  outline: none;
  color:  #F5F5F5;
  text-shadow: rgba(255,255,255,.1) -1px -1px 1px,rgba(0,0,0,.5) 1px 1px 1px;
}

#btn_newscrape_nav::after, #btn_newscrape_nav::before {
  content: "";
  position: absolute;
  height: 2rem;
  width: 5rem;
  transform: skewX(30deg);
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  z-index: 2;
  background-color: #30303d;
}
#btn_newscrape_nav::before {
  top: 0.6rem;
  left: 1rem;
  border-style: solid;
  border-width: 2px;
  border-image: linear-gradient(45deg,#8fe5e4d1 , #7a7bcc87) 1;
}
#btn_newscrape_nav::after {
  top: 1rem;
  left: 5rem;
  border-style: solid;
  border-width: 2px;
  border-image: linear-gradient(45deg,  #7a7bcc87, #8fe5e4d1) 1;
}
#btn_newscrape_nav:hover::before, #btn_newscrape_nav:hover::after {
  top: .6rem;
  transform: skewx(0deg);
}
#btn_newscrape_nav:hover::after {
  left: .4rem;
  transform: skewX(-30deg);
}
#btn_newscrape_nav:hover::before {
  left: 5rem;
  transform: skewX(-30deg);
  border-left: 0.1px;
}

</style>