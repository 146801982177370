<template>


    <nav class="shadow shadow-sm" aria-label="breadcrumb" id="breadcrumb" style="--bs-breadcrumb-divider: '>';">
        <ol class="breadcrumb ms-4 p-1">
          <li v-if="shouldShow" class="breadcrumb-item d-flex align-items-center text-white" >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
              <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"/>
            </svg>
            <router-link  class="ms-1 text-white text-decoration-none" :to="{ name:'Home' }">Home</router-link></li>
          <li  v-if="shouldShow" class="breadcrumb-item active" aria-current="page">{{ $route.name }}</li>
        </ol>
    </nav>


  </template>
  
  <script>
  export default {
    data() {
      return {
        shouldShow: false,
        items: [
          {
            text: 'Team',
            to: { name: 'Team' }
          },
          {
            text: 'Artikelprofil',
            to: { name: 'Artikelprofil' }
          },
          {
            text: 'Hilfe',
            to: { name: 'Hilfe' }
          }
        ]
      }
    },
    watch: {
    '$route.name'(newVal, oldVal) {
      this.shouldShow = newVal !== 'Home';
    }
  },
  mounted() {
    this.shouldShow = this.$route.name !== 'Home';
  }
};
</script>
<style>
#breadcrumb{
   background: linear-gradient(207deg, #8fe5e4d1, #7a7bcc87);
}
.breadcrumb-item{
  letter-spacint: 0.5px;
}

</style>