import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
    { //
        path: '/',
        name: 'Home',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: () => import('../views/ImpressumView.vue')
    },
    {
        path: '/hilfe',
        name: 'Hilfe',
        component: () => import('../views/HelpView.vue')
    },
    {
        path: '/nutzungsbedingungen',
        name: 'Nutzungsbedingungen',
        component: () => import('../views/TermsofuseView.vue')
    },
    {
        path: '/team',
        name: 'Team',
        component: () => import('../views/TeamView.vue')
    },
    {
        path: '/artikelprofil',
        name: 'Artikelprofil',
        component: () => import('../views/ArticleView.vue')
    },
    {
        path: '/wortprofil',
        name: 'Wortprofil',
        component: () => import('../views/WordView.vue')
    },
    {
        path: '/diskurstopics',
        name: 'Diskurstopics',
        component: () => import('../views/TopicView.vue')
    }

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router