import { createApp } from 'vue'
import App from './App.vue'

// Router
import router from './router'

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// import the fontawesome core
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// import specific icons and add to library
import { faPlus, faX, faHouse, faEnvelope, faChevronDown, faChevronUp, faTriangleExclamation, faSquare, faXmark} from '@fortawesome/free-solid-svg-icons'
library.add(faPlus, faX, faHouse, faEnvelope, faChevronDown, faChevronDown, faChevronUp, faTriangleExclamation, faSquare, faXmark)

// DataTable
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

//Notification mit Animation
import Notifications from '@kyvg/vue3-notification'
import velocity from 'velocity-animate'

//css Animation for page transitions
import 'animate.css';

//PrimeVue
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/lara-light-indigo/theme.css"; 
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";


//-- Elemente von PrimeVue
import Button from "primevue/button"
import Dialog from "primevue/dialog"
import Dropdown from 'primevue/dropdown';
import Message from 'primevue/message';
import Chips from 'primevue/chips';
import Chip from 'primevue/chip';
import InputText from 'primevue/inputtext';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Toolbar from 'primevue/toolbar';
import ProgressSpinner from 'primevue/progressspinner';
import AutoComplete from 'primevue/autocomplete';
import Calendar from 'primevue/calendar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Toast from 'primevue/toast';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row';                   // optional
import RadioButton from 'primevue/radiobutton';
import Card from 'primevue/card';
import Tag from 'primevue/tag';
import SelectButton from 'primevue/selectbutton';
import Listbox from 'primevue/listbox';
import Divider from 'primevue/divider';

createApp(App).
use(router).
use(Notifications, { velocity }).
use(PrimeVue).
component('font-awesome-icon', FontAwesomeIcon).
component('EasyDataTable', Vue3EasyDataTable).

component('Button', Button).
component('Divider', Divider).
component('Dialog', Dialog).
component('Dropdown', Dropdown).
component('Message', Message).
component('Chips', Chips).
component('Chip', Chip).
component('InputText', InputText).
component('ToggleButton', ToggleButton).
component('Toolbar', Toolbar).
component('ProgressSpinner', ProgressSpinner).
component('AutoComplete', AutoComplete).
component('Calendar', Calendar).
component('DataTable', DataTable).
component('Column', Column).
component('Tag', Tag).
component('ColumnGroup', ColumnGroup).
component('Row', Row).
component('Toast', Toast).
component('RadioButton', RadioButton).
component('SelectButton', SelectButton).
component("Card", Card).
component("Listbox", Listbox).
directive('tooltip', Tooltip).

mount('#app')

