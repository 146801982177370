<template>
  <div id="page-container" class="overflow-hidden">
    <NavHeader/>
    <BreadCrumb/>
   <div id="content-wrap">
     <!-- all other page content -->

     <router-view v-slot="{ Component }">
      <transition
       enter-active-class="animate__animated animate__fadeIn"
       leave-active-class="animate__animated animate__fadeOut" 
       mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>
     <NotifiCations/>
  
    
   </div>
   
   <NavFooter/>
   
 </div>
</template>

<script>
import NavFooter from './components/NavFooter.vue'
import NavHeader from './components/NavHeader.vue'
import BreadCrumb from './components/BreadCrumb.vue'
import NotifiCations from './components/NotifiCations.vue'

export default {
  name: 'App',
  components: {
    NavHeader,
    NavFooter, 
    BreadCrumb,
    NotifiCations
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

body {
  caret-color: transparent;
}

.amchartsDiv {
  width: 100%;
  height: 500px;
}
.select-items{
  z-index:100;
}

html, body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:400;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  min-height: 100vh;
  padding-bottom: 4rem;    /* Footer height */
  margin-bottom: 80px;
}

#footer {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  /* height: 20rem;            Footer height */
}
a {
  color:black !important;
}
.footer-links{
  color: white !important;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}
a:hover{
  color:grey !important;
}

#app {
  font-family: 'Source Sans Pro', sans-serif;;
  font-weight:400;
}

.text-xl{
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-sm{
  font-size: .95rem;
}
.text-xs{
  font-size: .8rem;
}
.card:hover{
  box-shadow: 0 5px 10px rgb(0 0 0 / 12%), 0 4px 8px rgb(0 0 0 / 6%);
}

</style>
